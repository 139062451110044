import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useFormContext } from 'react-hook-form';
import FormField from '@components/form_field';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { apiAdminService } from '@api';
import { TActivateUser } from '@xeppt/xeppt-sdk/types/admin';
import { passwordRegex } from '@const/regex';
import { passwordValidation } from '@utils/validation';
import { useQueryParams } from '@hooks/helpers/useQueryParams';

interface IProps {
    onSuccess: () => void;
}

const PasswordSection: FC<IProps> = ({ onSuccess }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.password'
    });
    const { labelLocale, submitLocale, requestErrorLocale, validationLocale } = useLocales();
    const {
        formState: { isValid },
        getValues
    } = useFormContext<TActivateUser>();
    const [token] = useQueryParams(['token']);

    const { handleRequest } = useApiMutation({
        method: (data: TActivateUser) => apiAdminService.activateUser(data),
        onSuccess: onSuccess,
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        const { password, password_confirmation } = getValues();
        handleRequest({ token: token as string, password, password_confirmation });
    };

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('password_1')} <span>{t('password_2')}</span> {t('password_3')}{' '}
                <span>{t('password_4')}</span>
                {t('password_5')} <span>{t('password_6')}</span>
            </Typography>
            <div className={styles.form}>
                <FormField
                    name="password"
                    renderComponent={({ value, ...props }) => (
                        <Input
                            {...props}
                            label={labelLocale('password')}
                            full
                            placeholder="••••••••"
                            type="password"
                            required
                            value={value}
                            passwordErrors={passwordValidation(value, validationLocale)}
                        />
                    )}
                    rules={{
                        required: true,
                        validation: (value: string) =>
                            passwordRegex.test(value) || validationLocale('password')
                    }}
                />
                <FormField
                    name="password_confirmation"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            label={labelLocale('confirm_password')}
                            full
                            placeholder="••••••••"
                            type="password"
                            required
                        />
                    )}
                    rules={{
                        required: true,
                        validation: (value: string) => {
                            const values = getValues();
                            return (
                                values.password === value || validationLocale('confirm_password')
                            );
                        }
                    }}
                />
                <Button
                    disabled={!isValid}
                    className={styles.next}
                    onClick={onSubmit}
                    size="normal"
                    variant="primary">
                    {submitLocale('next')}
                </Button>
            </div>
        </div>
    );
};

export default PasswordSection;
