export const handleRequestError = (code: number, conflictMessage?: string) => {
    switch (code) {
        case 400:
            return 'BAD_REQUEST';
        case 401:
            return 'UNAUTHORIZED';
        case 403:
            return 'FORBIDDEN';
        case 404:
            return 'NOT_FOUND';
        case 408:
            return 'REQUEST_TIMEOUT';
        case 409:
            return conflictMessage || 'CONFLICT';
        case 429:
            return 'TOO_MANY_REQUESTS';
        case 500:
            return 'INTERNAL_SERVER_ERROR';
        case 502:
            return 'BAD_GATEWAY';
        case 503:
            return 'SERVICE_UNAVAILABLE';
        case 504:
            return 'GATEWAY_TIMEOUT';
        default:
            return 'UNEXPECTED_ERROR';
    }
};

export default handleRequestError;
