import React from 'react';
import { getFirstLetter } from '@utils/index';
import useModalContext from '@hooks/context/useModalContext';
import { prettifyDate } from '@utils/date';
import styles from './styles.module.scss';

const PreviewChatModal = () => {
    const { modalData } = useModalContext();
    return (
        <div className={styles.wrapper}>
            <div className={styles.user_wrapper}>
                <div className={styles.avatar}>
                    {modalData?.avatar ? (
                        <img src={modalData?.avatar} alt={modalData?.name} />
                    ) : (
                        <p>{getFirstLetter(modalData?.name)}</p>
                    )}
                </div>
                <div>
                    <div className={styles.name}>{modalData?.name}</div>
                    <div className={styles.date}>{prettifyDate(modalData?.date, (val) => val)}</div>
                </div>
            </div>
            <img className={styles.img} src={modalData?.url} alt="image" />
        </div>
    );
};

export default PreviewChatModal;
