export const routes = {
    transactions: '/',
    consumers: '/consumers',
    admins: '/admins',
    admin: '/admins/:id',
    consumer: '/consumers/:id',
    logs: '/logs',
    tickets: '/tickets',
    signin: '/sign-in',
    not_found: '/404',
    signup: '/sign-up'
};
