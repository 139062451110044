import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import ContextController from '@components/form_field/context_controller';

export interface IComponentProps<T = string> {
    value: T;
    onChange: (value: T) => void;
    error: boolean;
    helperText?: string;
}

export interface IProps<T> {
    name: string;
    renderComponent: (props: IComponentProps<T>) => ReactElement;
    control?: Control<any>;
    rules?: any;
    defaultValue?: T;
}

const FormField = <T extends Record<any, any> | string | number | Date | null = string>({
    name,
    renderComponent,
    control,
    rules,
    defaultValue
}: IProps<T>) => {
    const CustomController = control ? Controller : ContextController;

    return (
        <CustomController
            control={control}
            name={name}
            defaultValue={defaultValue}
            //@ts-ignore
            render={({ field, fieldState: { error } }) => {
                return renderComponent({
                    value: field.value,
                    onChange: field.onChange,
                    error: !!error?.message,
                    helperText: error?.message
                });
            }}
            rules={rules}
        />
    );
};

export default FormField;
