import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import AuthProvider from './UI/layout_providers/auth_provider';
import SignInLayout from '@layouts/signin';
import ModalContextProvider from './context/modal_context';
import { routes } from '@const/routes';
import TransactionsLayout from '@layouts/transactions';
import GlobalProvider from './UI/layout_providers/global_provider';
import ConsumersLayout from '@layouts/consumers';
import LogsLayout from '@layouts/logs';
import TicketsLayout from '@layouts/tickets';
import ConsumerLayout from '@layouts/consumers/consumer';
import { UserContextProvider } from './context/user_context';
import AdminsLayout from '@layouts/admins';
import AdminLayout from '@layouts/admins/admin';
import NotFoundPage from '@layouts/not_found';
import SignUpLayout from '@layouts/signup';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<UserContextProvider />}>
            <Route element={<ModalContextProvider />}>
                <Route element={<AuthProvider />}>
                    <Route path={routes.signin} element={<SignInLayout />} />
                    <Route path={routes.signup} element={<SignUpLayout />} />
                </Route>
                <Route element={<GlobalProvider />}>
                    <Route path={routes.transactions} element={<TransactionsLayout />} />
                    <Route path={routes.consumers} element={<ConsumersLayout />} />
                    <Route path={routes.consumer} element={<ConsumerLayout />} />
                    <Route path={routes.admins} element={<AdminsLayout />} />
                    <Route path={routes.admin} element={<AdminLayout />} />
                    <Route path={routes.logs} element={<LogsLayout />} />
                    <Route path={routes.tickets} element={<TicketsLayout />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Route>
        </Route>
    )
);
