import React, { FC } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Status from '@components/common/status';
import moment from 'moment-timezone';
import Chat from '@components/chat';
import classNames from 'classnames/bind';
import { ETicketStatus, TTicket } from '@xeppt/xeppt-sdk/types';
import { capitalizeFirstLetter, getTicketStatusVariant } from '@utils/index';
import Button from '@components/common/button';

interface IProps {
    isOpen: boolean;
    ticket?: TTicket;
    onClose: () => void;
    refetchTicket: (id: string) => void;
}

const cx = classNames.bind(styles);

const ChatDrawer: FC<IProps> = ({ isOpen, onClose, ticket, refetchTicket }) => {
    return (
        <div className={cx([styles.wrapper, { isOpen }])}>
            <div className={styles.drawer}>
                <div className={styles.header_actions}>
                    <button onClick={onClose}>
                        <Icon name="close" />
                    </button>
                    {(ticket?.status === ETicketStatus.SOLVED ||
                        ticket?.status === ETicketStatus.CLOSED) && (
                        <Button variant="outlined" size="normal" leftIcon="check">
                            Reopen
                        </Button>
                    )}
                </div>
                <div className={styles.header_wrapper}>
                    <div className={styles.left_header}>
                        <div className={styles.request_data}>
                            <Typography variant="h5" weight="bold" className={styles.title}>
                                Request #{ticket?.id}
                            </Typography>
                            <Status
                                variant={getTicketStatusVariant(ticket?.status as ETicketStatus)}>
                                {capitalizeFirstLetter(ticket?.status || '')}
                            </Status>
                        </div>
                        <div className={styles.header_user}>
                            <div className={styles.user}>
                                <div className={styles.avatar}>CW</div>
                                <Typography variant="body3">Cameron Williamson</Typography>
                            </div>
                            <Typography variant="body3" className={styles.date}>
                                {moment(ticket?.createdAt).format('MM.DD.YYYY, HH:mm')}
                            </Typography>
                        </div>
                    </div>
                    <Typography variant="body3" className={styles.subject}>
                        Unable to Link Bank Account or Credit Card
                    </Typography>
                    <div className={styles.admin}>
                        <Typography variant="body3">Assigned to</Typography>
                        <div className={styles.avatar}>CW</div>
                        <Typography variant="body3">Admin adminovich</Typography>
                        <Icon name="edit" />
                    </div>
                </div>
                <Chat
                    createdAt={ticket?.createdAt}
                    messages={ticket?.comments || []}
                    users={ticket?.users || []}
                    refetchTicket={refetchTicket}
                    id={ticket?.id || ''}
                    status={ticket?.status}
                />
            </div>
            {isOpen && <div onClick={onClose} className={styles.overlay} />}
        </div>
    );
};

export default ChatDrawer;
