import React, { FC, useRef, useState } from 'react';
import Input from '@components/common/input';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import Checkbox from '@components/common/checkbox';
import useClickOutside from '@hooks/helpers/useClickOutside';

interface IProps {
    values: (string | number)[];
    onChange: (value: (string | number)[]) => void;
    label?: string;
    readOnly?: boolean;
    placeholder?: string;
    items: { label: string; value: string | number }[];
    full?: boolean;
}

const cx = classNames.bind(styles);

const MultiSelect: FC<IProps> = ({
    values,
    placeholder,
    full,
    label,
    items,
    onChange,
    readOnly
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    const handleChange = (value: string | number) => {
        const newValues = values;

        if (newValues.includes(value)) {
            onChange(newValues.filter((item) => item !== value));
        } else {
            onChange([...values, value]);
        }
    };

    useClickOutside({
        ref,
        callback: () => setIsOpen(false)
    });

    return (
        <div className={cx([styles.wrapper, { isFull: full }])} ref={ref}>
            <Input
                rightIcon="nav_down"
                value={values
                    .map((item) => items.find((point) => point.value === item)?.label)
                    .join(', ')}
                placeholder={placeholder}
                label={label}
                full={full}
                className={cx([{ isOpen }])}
                readOnly={readOnly}
                onFocus={() => setIsOpen(true)}
                onClickIcon={() => setIsOpen((state) => !state)}
            />
            <div className={cx([styles.dropdown_wrapper, { isOpen }])}>
                <div className={styles.dropdown}>
                    {items.map((item, i) => (
                        <button key={i} onClick={() => handleChange(item.value)}>
                            <Checkbox variant="gold" checked={values.includes(item.value)} />{' '}
                            {item.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MultiSelect;
