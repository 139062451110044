import React, { FC } from 'react';
import moment from 'moment-timezone';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Info from '@components/common/info';
import { getFirstLetter } from '@utils/index';
import { TTicketAttachment } from '@xeppt/xeppt-sdk/types';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';

interface IProps {
    avatar?: string;
    name: string;
    message: string;
    attachments?: TTicketAttachment[];
    className?: string;
    isSender?: boolean;
    isShowUser?: boolean;
    createdAt: Date;
}

const cx = classNames.bind(styles);

const Message: FC<IProps> = ({
    avatar,
    message,
    attachments = [],
    isSender,
    name,
    isShowUser,
    createdAt,
    className
}) => {
    const { onOpen, changeModalData } = useModalContext();
    const handleOpenPreviewAttachmentModal = (data: {
        url: string;
        name: string;
        date: Date;
        avatar?: string;
    }) => {
        changeModalData(data);
        onOpen({
            modalId: modalIds.PREVIEW_CHAT_ATTACHMENT
        });
    };
    return (
        //@ts-ignore
        <div className={cx(styles.message_container, [className, { isSender }])}>
            {isShowUser && (
                <Info variant={isSender ? 'left' : 'right'} content={name}>
                    <div className={styles.avatar}>
                        {avatar ? <img src={avatar} alt={name} /> : <p>{getFirstLetter(name)}</p>}
                    </div>
                </Info>
            )}
            <div className={styles.message_wrapper}>
                <div className={styles.message}>
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                    <div className={styles.time}>{moment(createdAt).format('HH:mm')}</div>
                </div>

                {attachments?.length > 0 && (
                    <div className={styles.attachments_wrapper}>
                        {attachments.map((item, i) => {
                            if (i === 0) {
                                return (
                                    <img
                                        key={i}
                                        onClick={() =>
                                            handleOpenPreviewAttachmentModal({
                                                url: item.url,
                                                name,
                                                date: createdAt,
                                                avatar
                                            })
                                        }
                                        src={item.url}
                                        alt="Message image"
                                    />
                                );
                            }
                        })}
                        <div className={styles.attachments}>
                            {attachments.map((item, i) => {
                                if (i !== 0) {
                                    return (
                                        <img
                                            onClick={() =>
                                                handleOpenPreviewAttachmentModal({
                                                    url: item.url,
                                                    name,
                                                    date: createdAt,
                                                    avatar
                                                })
                                            }
                                            key={i}
                                            src={item.url}
                                            alt="Message image"
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default Message;
