import React, { FC, useEffect } from 'react';
import DataInfo from '@components/data_info';
import FormField from '@components/form_field';
import { useForm } from '@hooks/helpers/useForm';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import DatePicker from '@components/date_picker';
import styles from './styles.module.scss';
import { EUserStatus, TUser } from '@xeppt/xeppt-sdk/types';
import { userStatuses } from '@const/index';

interface IProps {
    admin?: TUser;
}

const PersonalSection: FC<IProps> = ({ admin }) => {
    const form = useForm({});
    const { labelLocale } = useLocales();

    useEffect(() => {
        if (admin) {
            form.reset({
                firstName: admin?.profile?.firstName,
                lastName: admin?.profile?.lastName,
                email: admin?.email,
                id: admin?.id,
                createdAt: admin?.createdAt,
                isVerified:
                    admin?.status !== EUserStatus.UNVERIFIED &&
                    admin?.status !== EUserStatus.UNCONFIRMED,
                status: admin?.status
            });
        }
    }, [admin]);

    return (
        <div className={styles.wrapper}>
            <DataInfo label="General information" form={form}>
                <FormField
                    name="firstName"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('first_name')} />;
                    }}
                />
                <FormField
                    name="lastName"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('last_name')} />;
                    }}
                />
                <FormField
                    name="email"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('email')} />;
                    }}
                />
                <FormField
                    name="id"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('user_id')} />;
                    }}
                />
                <FormField<string | null>
                    name="createdAt"
                    renderComponent={(props) => {
                        return (
                            <DatePicker
                                readOnly
                                {...props}
                                label={labelLocale('date_created')}
                                full
                            />
                        );
                    }}
                />
                <FormField
                    name="status"
                    renderComponent={({ value, ...props }) => {
                        return (
                            <Input
                                readOnly
                                full
                                {...props}
                                label={labelLocale('status')}
                                value={
                                    userStatuses.find(
                                        //@ts-ignore
                                        (item) => item.value === (value as EUserStatus)
                                    )?.label
                                }
                            />
                        );
                    }}
                />
            </DataInfo>
        </div>
    );
};

export default PersonalSection;
