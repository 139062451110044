import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { supportedLanguages } from './const';
import { useTranslation } from 'react-i18next';
import '@locales/i18n';
import '@styles/global.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { router } from './router';
import { ToastContainer } from 'react-toastify';
import { MantineProvider } from '@mantine/core';

const App = () => {
    const { i18n } = useTranslation();
    useEffect(() => {
        if (
            localStorage.getItem('language') &&
            supportedLanguages.includes(localStorage.getItem('language') || 'en')
        ) {
            i18n.changeLanguage(localStorage.getItem('language') || 'en');
        }
    }, []);

    return (
        <>
            <MantineProvider withGlobalClasses>
                <RouterProvider router={router} />
                <ToastContainer />
            </MantineProvider>
        </>
    );
};

export default App;
