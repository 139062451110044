import { emailRegex, passwordRegex } from '@const/regex';

export const validateEmail = (value: string, message?: string) => {
    return emailRegex.test(value) || message;
};

export const validatePassword = (value: string, message?: string) => {
    return passwordRegex.test(value) || message;
};

export const validatePhoneNumber = (value: string, message?: string) => {
    return value.length !== 10 ? message : false;
};

//expire date in MM/YY format
export const validateExpiryDate = (expirationDate: string) => {
    const [month, year] = expirationDate.split('/').map(Number);
    if (!month || !year || month < 1 || month > 12) {
        return false;
    }
    const now = new Date();
    const expDate = new Date(Number(`20${year}`), month - 1, 1);
    const firstOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

    return expDate >= firstOfNextMonth;
};

export const passwordValidation = (password: string, t: (val: string) => string) => {
    const validations = [
        {
            regex: /.{12,}/,
            message: t('password_length')
        },
        {
            regex: /[A-Z]/,
            message: t('password_capitalize')
        },
        {
            regex: /[a-z]/,
            message: t('password_letter')
        },
        {
            regex: /\d/,
            message: t('password_number')
        },
        {
            regex: /[!@#$%^~&*()_+\-=[\]{};':"\\|,.<>/?]+/,
            message: t('password_special')
        }
    ];

    return validations.map(({ message, regex }) => {
        return {
            message,
            isValid: regex.test(password)
        };
    });
};
