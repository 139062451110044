import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

export const useBack = () => {
    const navigate = useNavigate();

    const handleNavigateBack = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate(routes.consumers);
        }
    };

    return {
        handleNavigateBack
    };
};
