import React, { useState } from 'react';
import DataInfo from '@components/data_info';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Select from '@components/common/select';
import Drawer from '@components/drawer';
import TicketsLayout from '@layouts/tickets';

const RequestsSection = () => {
    return (
        <div className={styles.wrapper}>
            <TicketsLayout />
        </div>
    );
};

export default RequestsSection;
