import { Country } from 'country-state-city';
import { EAddressType } from '@xeppt/xeppt-sdk/types/user';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus
} from '@xeppt/xeppt-sdk/types/transaction';
import * as XLSX from 'xlsx';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import {
    ECardStatus,
    ECardType,
    ELogLevel,
    ETicketStatus,
    EUserStatus,
    TTicketMessage
} from '@xeppt/xeppt-sdk/types';
import moment from 'moment-timezone';

export const getFirstLetter = (string?: string) => {
    if (!string) return '';
    return string.charAt(0);
};

export const hideCardNumber = (
    value: number | string,
    type?: 'full' | 'partial',
    variant?: 'dots' | 'stars'
) => {
    const separator = variant === 'dots' ? '••••' : '****';
    const string = String(value);
    if (type === 'full') {
        return `${separator} ${string.slice(12)}`;
    } else {
        return `${string.slice(0, 4)} ${separator} ${separator} ${string.slice(12)}`;
    }
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hidePhoneNumber = (value: string | number) => {
    const str = String(value);
    return (
        str
            .substring(0, str.length - 4)
            .split('')
            .map(() => '•')
            .join('') + str.substring(str.length - 4, str.length)
    );
};

export const joiningArrayWords = (array: string[]) => {
    if (array.length === 0) {
        return '';
    } else if (array.length === 1) {
        return array[0];
    } else {
        const joinedFormats = array.slice(0, -1).join(', ');
        return `${joinedFormats} or ${array[array.length - 1]}`;
    }
};

export const getBirthDisabledDates = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    return maxDate;
};

export const prettifyGoogleAddress = ({ address_components }: any) => {
    let streetName = '';
    let streetNumber = '';
    const address = {
        country: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: '',
        region: ''
    };

    for (const component of address_components) {
        const componentType = component.types[0];
        switch (componentType) {
            case 'street_number': {
                streetNumber = component.long_name;
                break;
            }
            case 'route': {
                streetName = component.long_name;
                break;
            }
            case 'locality': {
                address['city'] = component.long_name;
                break;
            }
            case 'country': {
                address['country'] = component.short_name;
                break;
            }
            case 'postal_code': {
                address['zipCode'] = component.long_name;
                break;
            }
            case 'administrative_area_level_1': {
                address['region'] += component.long_name;
                break;
            }
            default:
                break;
        }
    }
    if (
        address.country === '' ||
        address.city === '' ||
        streetName === '' ||
        streetNumber === '' ||
        address.zipCode === '' ||
        address.region === ''
    ) {
        return undefined;
    } else {
        return {
            ...address,
            address1: streetName + ' ' + streetNumber
        };
    }
};

export const getPhoneCodeFromIso = (isoCode: string) => {
    return Country.getCountryByCode(isoCode)?.phonecode || '1';
};

export const getAddressFormName = (type: EAddressType) => {
    if (type === EAddressType.BUSINESS) {
        return 'businessAddress';
    } else if (type === EAddressType.REGISTRATION) {
        return 'registrationAddress';
    } else {
        return 'tradingAddress';
    }
};
export const getAddressName = (type: EAddressType, localization: (val: string) => string) => {
    if (type === EAddressType.BUSINESS) {
        return localization('business');
    } else if (type === EAddressType.REGISTRATION) {
        return localization('registration');
    } else {
        return localization('trading');
    }
};

export const getTransactionAction = (action?: ETransactionAction) => {
    switch (action) {
        case ETransactionAction.SEND_MONEY:
            return 'Send money';
        case ETransactionAction.RECEIVE_MONEY:
            return 'Receive money';
        case ETransactionAction.REQUEST_MONEY:
            return 'Request money';
        case ETransactionAction.TRANSACTION_FEE:
            return 'Transaction fee';
        case ETransactionAction.BILL_PAYMENT:
            return 'Bill Payment';
        case ETransactionAction.REQUEST_CARD:
            return 'Request card';
        case ETransactionAction.LOAD_CARD_BALANCE:
            return 'Load card';
        case ETransactionAction.INCOMING_REQUEST_MONEY:
            return 'Incoming request';
        default:
            return '';
    }
};

export const getTransactionMethod = (action?: ETransactionMethod) => {
    switch (action) {
        case ETransactionMethod.E_TRANSFER:
            return 'E-transfer';
        case ETransactionMethod.EFT:
            return 'Bank';
        case ETransactionMethod.CARD:
            return 'Card';
        case ETransactionMethod.WALLET:
            return 'Wallet';
        case ETransactionMethod.BILL:
            return 'Bill payment';
        case ETransactionMethod.EPS_CARD:
            return 'Linked card';
        default:
            return '';
    }
};

export const getTransactionStatusColor = (status?: ETransactionStatus) => {
    switch (status) {
        case ETransactionStatus.APPROVED:
            return 'success';
        case ETransactionStatus.PENDING:
            return 'warning';
        case ETransactionStatus.DECLINED:
        case ETransactionStatus.CANCELED:
            return 'error';
        default:
            return 'success';
    }
};

export const getLogsStatusColor = (status?: ELogLevel) => {
    switch (status) {
        case ELogLevel.INFO:
            return 'success';
        case ELogLevel.WARNING:
            return 'warning';
        case ELogLevel.ERROR:
            return 'error';
        default:
            return 'success';
    }
};

export const getConsumerStatusColor = (status?: EConsumerStatus) => {
    switch (status) {
        case EConsumerStatus.ACTIVE:
            return 'success';
        case EConsumerStatus.PENDING:
            return 'warning';
        case EConsumerStatus.UNVERIFIED:
        case EConsumerStatus.UNCONFIRMED:
        case EConsumerStatus.BLOCKED:
        case EConsumerStatus.SUSPENDED:
            return 'error';
        default:
            return 'success';
    }
};

export const getAdminStatusColor = (status?: EUserStatus) => {
    switch (status) {
        case EUserStatus.ACTIVE:
            return 'success';
        case EUserStatus.PENDING:
            return 'warning';
        case EUserStatus.UNVERIFIED:
        case EUserStatus.UNCONFIRMED:
        case EUserStatus.BLOCKED:
        case EUserStatus.SUSPENDED:
            return 'error';
        default:
            return 'success';
    }
};

export const getTransactionStatus = (status?: ETransactionStatus) => {
    switch (status) {
        case ETransactionStatus.APPROVED:
            return 'Approved';
        case ETransactionStatus.PENDING:
            return 'Pending';
        case ETransactionStatus.DECLINED:
            return 'Declined';
        case ETransactionStatus.CANCELED:
            return 'Canceled';
        case ETransactionStatus.ERROR:
            return 'Error';
    }
};

export const getLogsLevel = (status?: ELogLevel) => {
    switch (status) {
        case ELogLevel.INFO:
            return 'INFO';
        case ELogLevel.WARNING:
            return 'WARNING';
        case ELogLevel.ERROR:
            return 'ERROR';
    }
};

export const getConsumerStatus = (status?: EConsumerStatus) => {
    switch (status) {
        case EConsumerStatus.ACTIVE:
            return 'Active';
        case EConsumerStatus.PENDING:
            return 'Pending';
        case EConsumerStatus.UNVERIFIED:
            return 'Unverified';
        case EConsumerStatus.UNCONFIRMED:
            return 'Unconfirmed';
        case EConsumerStatus.BLOCKED:
            return 'Blocked';
        case EConsumerStatus.SUSPENDED:
            return 'Suspended';
    }
};

export const getAdminStatus = (status?: EUserStatus) => {
    switch (status) {
        case EUserStatus.ACTIVE:
            return 'Active';
        case EUserStatus.PENDING:
            return 'Verification pending';
        case EUserStatus.UNVERIFIED:
            return 'Unverified';
        case EUserStatus.UNCONFIRMED:
            return 'Unconfirmed';
        case EUserStatus.BLOCKED:
            return 'Blocked';
        case EUserStatus.SUSPENDED:
            return 'Suspended';
    }
};

export const downloadFileFromBlob = (data: any, name: string) => {
    const a = document.createElement('a');
    a.download = name;
    a.href = URL.createObjectURL(data);
    a.addEventListener('click', () => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};

export const formatDataToXlsx = (data: any) => {
    const keys: any[] = [];
    data.forEach((item: any) => {
        Object.keys(item).forEach((key) => {
            if (!keys.includes(key)) {
                keys.push(camelize(key));
            }
        });
    });
    const widthArray = keys.map((item) => {
        return {
            wch: data.reduce((w: any, r: any) => Math.max(w, r[item]?.length || 0), 13)
        };
    });
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheethtml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(data);
    ws['!cols'] = widthArray;
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: fileType });
};

export const camelize = (str: string) => {
    return str
        .replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
            return index === 0 ? word.toUpperCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
};

export const removeEmptyArrays = (obj: { [key: string]: any }) => {
    const result = {};

    for (const key in obj) {
        //eslint-disable-next-line
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (!(Array.isArray(value) && value.length === 0)) {
                //@ts-ignore
                result[key] = value;
            }
        }
    }

    return result;
};

export const getDateOneMonthAgo = () => {
    const currentDate = new Date();
    const pastDate = new Date(currentDate);

    pastDate.setMonth(currentDate.getMonth() - 1);

    return pastDate;
};

export const prettifyCardStatus = (status: ECardStatus) => {
    switch (status) {
        case ECardStatus.ACTIVE:
            return 'Active';
        case ECardStatus.TEMPORARY_CLOSED:
            return 'Inactive';
        case ECardStatus.WAITING_FOR_ACTIVATION:
            return 'Waiting for activation';
        case ECardStatus.LOST:
            return 'Lost';
        case ECardStatus.STOLEN:
            return 'Stolen';
        case ECardStatus.OTHER:
            return 'Other';
        case ECardStatus.FRAUD:
            return 'Fraud';
        case ECardStatus.CUSTOMER_REQUEST:
            return 'Requested';
        case ECardStatus.CLOSED:
            return 'Closed';
        case ECardStatus.LEGAL_CLOSED:
            return 'Legal closed';
        case ECardStatus.FREEZED:
            return 'Frozen';
    }
};

export const getTagVariant = (status: ECardStatus) => {
    switch (status) {
        case ECardStatus.ACTIVE:
            return 'success';
        case ECardStatus.OTHER:
        case ECardStatus.WAITING_FOR_ACTIVATION:
        case ECardStatus.CUSTOMER_REQUEST:
            return 'warning';
        case ECardStatus.TEMPORARY_CLOSED:
        case ECardStatus.LOST:
        case ECardStatus.STOLEN:
        case ECardStatus.FRAUD:
        case ECardStatus.CLOSED:
        case ECardStatus.LEGAL_CLOSED:
        case ECardStatus.FREEZED:
            return 'error';
    }
};

export const prettifyCardType = (type?: ECardType) => {
    switch (type) {
        case ECardType.PHYSICAL:
            return 'Primary';
        case ECardType.VIRTUAL:
            return 'Virtual';
        case ECardType.VIRTUAL_SUPPLEMENTARY:
            return 'Virtual Supplementary';
        case ECardType.PHYSICAL_SUPPLEMENTARY:
            return 'Primary Supplementary';
        default:
            return 'Primary';
    }
};

export function getDefaultPhoneCode(tz?: string) {
    const countries = Country.getAllCountries();
    const localTimeZone = tz || moment.tz.guess();
    const defaultCountry = Country.getCountryByCode('CA');

    const localCountry = countries.find((country) =>
        country?.timezones?.find((data) => data.zoneName === localTimeZone)
    );

    return (localCountry?.phonecode as string) || (defaultCountry?.phonecode as string);
}

export const getTicketStatusVariant = (status: ETicketStatus) => {
    switch (status) {
        case ETicketStatus.NEW:
            return 'blue';
        case ETicketStatus.HOLD:
            return 'purple';
        case ETicketStatus.OPEN:
            return 'warning';
        case ETicketStatus.PENDING:
            return 'warning';
        case ETicketStatus.SOLVED:
            return 'success';
        case ETicketStatus.CLOSED:
            return 'gray';
        default:
            return 'gray';
    }
};

interface GroupedMessages {
    date: string; // Formatted as DD.MM.YYYY
    messages: TTicketMessage[];
}

export const groupMessagesByDate = (
    messages: TTicketMessage[],
    userTimezone: string // Pass the user's timezone as a parameter
): GroupedMessages[] => {
    const groupedMessages: Record<string, TTicketMessage[]> = {};

    // Group messages by date (adjusted to user's timezone)
    messages.forEach((msg) => {
        const dateKey = moment(msg.createdAt).tz(userTimezone).format('MM.DD.YYYY'); // Adjust to user's timezone
        if (!groupedMessages[dateKey]) {
            groupedMessages[dateKey] = [];
        }
        groupedMessages[dateKey].push(msg);
    });

    // Convert groupedMessages into an array, sort messages inside each group, and sort groups by date
    return Object.entries(groupedMessages)
        .map(([date, messages]) => ({
            date,
            messages: messages.sort((a, b) => {
                // Sort messages within the group by `created_at` (oldest to newest)
                const timeA = moment(a.createdAt).tz(userTimezone).valueOf();
                const timeB = moment(b.createdAt).tz(userTimezone).valueOf();
                return timeA - timeB; // Ascending order (oldest to newest)
            })
        }))
        .sort((a, b) => {
            // Sort groups by `date` in ascending order (oldest group first)
            //@ts-ignore
            const dateA = moment(a.date, 'MM.DD.YYYY').tz(userTimezone).valueOf();
            const dateB = moment(b.date, 'MM.DD.YYYY').tz(userTimezone).valueOf();
            return dateA - dateB; // Ascending order
        });
};

export type TAcceptedFile =
    | '.doc'
    | '.xls'
    | 'image/png'
    | 'image/jpg'
    | 'image/jpeg'
    | '.pdf'
    | '.docx';

export const fileFormatString = {
    '.doc': 'doc',
    '.xls': 'xls',
    'image/png': 'png',
    'image/jpg': 'jpg',
    'image/jpeg': 'jpeg',
    '.pdf': 'pdf',
    '.docx': 'docx'
};
