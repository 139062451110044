import React, { useState } from 'react';
import styles from './styles.module.scss';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import PasswordSection from '@sections/signup/password';
import CongratsSection from '@sections/signup/congrats';

const SignUpLayout = () => {
    const formMethods = useForm({});
    const [isVerified, setIsVerified] = useState(false);

    return (
        <div className={styles.wrapper}>
            <FormProvider {...formMethods}>
                {isVerified ? (
                    <CongratsSection />
                ) : (
                    <PasswordSection onSuccess={() => setIsVerified(true)} />
                )}
            </FormProvider>
        </div>
    );
};

export default SignUpLayout;
