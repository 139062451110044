import React, { FC, useEffect, useState } from 'react';
import DataInfo from '@components/data_info';
import FormField from '@components/form_field';
import { useForm } from '@hooks/helpers/useForm';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import DatePicker from '@components/date_picker';
import Phone from '@components/phone';
import Address from '@components/address';
import styles from './styles.module.scss';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import { EAddressType, EUserStatus, TConsumer } from '@xeppt/xeppt-sdk/types';
import { userStatuses } from '@const/index';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiAdminService } from '@api';
import { toast } from 'react-toastify';

interface IProps {
    consumer?: TConsumer;
}

const PersonalSection: FC<IProps> = ({ consumer }) => {
    const { requestErrorLocale } = useLocales();
    const [isEditAddress, setIsEditAddress] = useState({
        billing: false,
        business: false,
        registration: false,
        trading: false
    });
    const form = useForm({});
    const { labelLocale } = useLocales();

    useEffect(() => {
        if (consumer) {
            form.reset({
                firstName: consumer?.profile?.firstName,
                lastName: consumer?.profile?.lastName,
                email: consumer?.email,
                birthDate: consumer?.profile?.birthDate,
                id: consumer?.id,
                createdAt: consumer?.createdAt,
                isVerified:
                    consumer?.status !== EConsumerStatus.UNVERIFIED &&
                    consumer?.status !== EConsumerStatus.UNCONFIRMED,
                status: consumer?.status,
                //@ts-ignore
                billingAddress: consumer?.billingAddress,
                //@ts-ignore
                tradingAddress: consumer?.tradingAddress,
                //@ts-ignore
                registrationAddress: consumer?.registrationAddress,
                //@ts-ignore
                businessAddress: consumer?.businessAddress,
                phoneCode: consumer?.phoneCode,
                phoneNumber: consumer?.phoneNumber
            });
        }
    }, [consumer]);

    const { handleRequest: handleUpdateAddress } = useApiMutation({
        method: (type: EAddressType) => {
            const values = form.getValues();
            const getAddress = () => {
                switch (type) {
                    case EAddressType.REGISTRATION:
                        return values.registrationAddress;
                    case EAddressType.BILLING:
                        return values.billingAddress;
                    case EAddressType.BUSINESS:
                        return values.businessAddress;
                    case EAddressType.TRADING:
                        return values.tradingAddress;
                }
            };
            return apiAdminService.updateConsumerAddress(consumer?.id || '', getAddress(), type);
        },
        onSuccess: () => {
            toast.success('Address was updated successfully');
            setIsEditAddress({
                trading: false,
                business: false,
                registration: false,
                billing: false
            });
        },
        onError: requestErrorLocale
    });

    return (
        <div className={styles.wrapper}>
            <DataInfo label="General information" form={form}>
                <FormField
                    name="firstName"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('first_name')} />;
                    }}
                />
                <FormField
                    name="lastName"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('last_name')} />;
                    }}
                />
                <FormField
                    name="email"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('email')} />;
                    }}
                />
                <FormField<string | null>
                    name="birthDate"
                    renderComponent={(props) => {
                        return (
                            <DatePicker
                                readOnly
                                {...props}
                                label={labelLocale('birth_date')}
                                full
                            />
                        );
                    }}
                />
                {/*<FormField*/}
                {/*    name="occupancy"*/}
                {/*    renderComponent={(props) => {*/}
                {/*        return <Input full {...props} label={labelLocale('occupation')} />;*/}
                {/*    }}*/}
                {/*/>*/}
                <FormField
                    name="id"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('user_id')} />;
                    }}
                />
                <FormField<string | null>
                    name="createdAt"
                    renderComponent={(props) => {
                        return (
                            <DatePicker
                                readOnly
                                {...props}
                                label={labelLocale('date_created')}
                                full
                            />
                        );
                    }}
                />
                <FormField
                    name="isVerified"
                    renderComponent={({ value, ...props }) => {
                        console.log(props);
                        return (
                            <Input
                                readOnly
                                full
                                {...props}
                                label={labelLocale('verification')}
                                value={value ? 'Verified' : 'Not verified'}
                            />
                        );
                    }}
                />
                <FormField
                    name="status"
                    renderComponent={({ value, ...props }) => {
                        return (
                            <Input
                                readOnly
                                full
                                {...props}
                                label={labelLocale('status')}
                                value={
                                    userStatuses.find(
                                        //@ts-ignore
                                        (item) => item.value === (value as EUserStatus)
                                    )?.label
                                }
                            />
                        );
                    }}
                />
                <Phone readOnly label={labelLocale('phone')} isForm />
                <FormField
                    name="email"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('email')} />;
                    }}
                />
            </DataInfo>
            {/*//@ts-ignore*/}
            {consumer?.billingAddress && (
                <DataInfo
                    label="Billing address"
                    showEdit
                    isEdit={isEditAddress.billing}
                    form={form}
                    full
                    onChangeEdit={() => {
                        if (!isEditAddress.billing) {
                            setIsEditAddress((state) => ({
                                ...state,
                                billing: !state.billing
                            }));
                        } else {
                            handleUpdateAddress(EAddressType.BILLING);
                        }
                    }}>
                    <Address isFull name="billingAddress" disabled={!isEditAddress} />
                </DataInfo>
            )}
            {/*//@ts-ignore*/}
            {consumer?.businessAddress && (
                <DataInfo
                    label="Business address"
                    showEdit
                    isEdit={isEditAddress.business}
                    form={form}
                    full
                    onChangeEdit={() => {
                        if (!isEditAddress.business) {
                            setIsEditAddress((state) => ({
                                ...state,
                                business: !state.business
                            }));
                        } else {
                            handleUpdateAddress(EAddressType.BUSINESS);
                        }
                    }}>
                    <Address isFull name="businessAddress" disabled={!isEditAddress} />
                </DataInfo>
            )}
            {/*//@ts-ignore*/}
            {consumer?.registrationAddress && (
                <DataInfo
                    label="Registration adress"
                    showEdit
                    isEdit={isEditAddress.registration}
                    form={form}
                    full
                    onChangeEdit={() => {
                        if (!isEditAddress.registration) {
                            setIsEditAddress((state) => ({
                                ...state,
                                registration: !state.registration
                            }));
                        } else {
                            handleUpdateAddress(EAddressType.REGISTRATION);
                        }
                    }}>
                    <Address isFull name="registrationAddress" disabled={!isEditAddress} />
                </DataInfo>
            )}
            {/*//@ts-ignore*/}
            {consumer?.tradingAddress && (
                <DataInfo
                    label="Trading address"
                    showEdit
                    isEdit={isEditAddress.trading}
                    form={form}
                    full
                    onChangeEdit={() => {
                        if (!isEditAddress.trading) {
                            setIsEditAddress((state) => ({
                                ...state,
                                trading: !state.trading
                            }));
                        } else {
                            handleUpdateAddress(EAddressType.TRADING);
                        }
                    }}>
                    <Address isFull name="tradingAddress" disabled={!isEditAddress} />
                </DataInfo>
            )}
        </div>
    );
};

export default PersonalSection;
