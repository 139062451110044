import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import moment from 'moment-timezone';
import Message from '@components/chat/message';
import classNames from 'classnames/bind';
import Input from '@components/common/input';
import { ETicketStatus, TTicketMessage, TTicketUser } from '@xeppt/xeppt-sdk/types';
import { groupMessagesByDate } from '@utils/index';
import { Icon } from '@components/icons';
import Info from '@components/common/info';
import { useUserContext } from '@hooks/context/useUserContext';
import { TAcceptedFile } from '@components/common/upload_file_input';
import { useSupport } from '@hooks/api/useSupport';

interface IProps {
    className?: string;
    isShowSender?: boolean;
    isShowReceiver?: boolean;
    withoutInputBorder?: boolean;
    messages: TTicketMessage[];
    users: TTicketUser[];
    createdAt?: Date;
    id: string;
    status?: ETicketStatus;
    refetchTicket: (id: string) => void;
}

const cx = classNames.bind(styles);

interface FileInfo {
    name: string;
    preview?: string;
    id: number;
    size: number;
    type: TAcceptedFile;
}

const Chat: FC<IProps> = ({
    className,
    isShowSender = true,
    withoutInputBorder = false,
    isShowReceiver = true,
    id,
    messages,
    refetchTicket,
    users,
    status,
    createdAt
}) => {
    const [newMessage, setNewMessage] = useState('');
    const divRef = useRef(null);
    const fileInputRef = useRef(null);
    const addFileInputRef = useRef(null);
    const [files, setFiles] = useState<FormData[]>([]);
    const [filesInfo, setFilesInfo] = useState<FileInfo[]>([]);
    const { user } = useUserContext();

    const { handleSendMessage } = useSupport({
        isInitialGetList: false
    });

    const scrollToBottom = () => {
        if (divRef.current) {
            //@ts-ignore
            divRef.current?.scrollTo({
                //@ts-ignore
                top: divRef.current?.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, []);

    const handleUpload = async (e: ChangeEvent<HTMLInputElement>, isAdd?: boolean) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e?.target?.files;
        if (files) {
            // Clear existing values
            if (!isAdd) {
                setFiles([]);
                setFilesInfo([]);
            }

            const uploadedFilesInfo: FileInfo[] = [];
            Array.from(files).forEach((item) => {
                let preview: string | undefined = undefined;
                // Collect file details (name and size)
                if (item && item.type.includes('image/')) {
                    preview = URL.createObjectURL(item);
                }
                uploadedFilesInfo.push({
                    id: Math.random(),
                    name: item.name,
                    size: item.size,
                    type: item.type as TAcceptedFile,
                    preview
                });

                // Append file to FormData
                const formData = new FormData();
                formData.append('file', item);
                setFiles((state) => [...state, formData]);
            });

            // Update state with file details
            if (isAdd) {
                setFilesInfo((state) => [...state, ...uploadedFilesInfo]);
            } else {
                setFilesInfo(uploadedFilesInfo);
            }
        }
    };

    const handleUploadClick = () => {
        //@ts-ignore
        fileInputRef.current.click();
    };

    const handleAddUploadClick = () => {
        //@ts-ignore
        addFileInputRef.current.click();
    };

    return (
        <div className={cx(styles.wrapper, [className])}>
            <div className={styles.messages_wrapper} ref={divRef}>
                <div className={styles.date_divider}>
                    <span>Chat started: {moment(createdAt).format('ddd, MM.DD.YYYY')}</span>
                </div>
                {groupMessagesByDate(messages, user?.profile?.timezone as string).map((item, i) => {
                    return (
                        <>
                            {i !== 0 && (
                                <div className={styles.date_divider}>
                                    <span>{moment(item.date).format('ddd, MM.DD.YYYY')}</span>
                                </div>
                            )}
                            {item.messages.map((item) => {
                                return (
                                    <Message
                                        key={item.id}
                                        name={
                                            users.find((user) => user.id === item.authorId)?.name ||
                                            ''
                                        }
                                        avatar={
                                            users.find((user) => user.id === item.authorId)?.avatar
                                        }
                                        message={item.body}
                                        attachments={item.attachments}
                                        isSender={
                                            users.find((user) => user.id === item.authorId)
                                                ?.name ===
                                            `${user?.profile?.firstName} ${user?.profile?.lastName}`
                                        }
                                        isShowUser={isShowReceiver}
                                        createdAt={item.createdAt}
                                    />
                                );
                            })}
                        </>
                    );
                })}
            </div>
            {status !== ETicketStatus.SOLVED && status !== ETicketStatus.CLOSED && (
                <div className={cx([styles.input_wrapper, { withoutInputBorder }])}>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleUpload}
                        size={(10 * 10) ^ 6}
                        accept={[
                            '.doc',
                            'image/png',
                            'image/jpg',
                            'image/jpeg',
                            '.pdf',
                            '.docx'
                        ].join(',')}
                        id={id}
                        multiple
                    />
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={addFileInputRef}
                        onChange={(e) => handleUpload(e, true)}
                        size={(10 * 10) ^ 6}
                        accept={[
                            '.doc',
                            'image/png',
                            'image/jpg',
                            'image/jpeg',
                            '.pdf',
                            '.docx'
                        ].join(',')}
                        id={id}
                        multiple
                    />
                    {filesInfo.length > 0 && (
                        <div className={styles.new_attachments}>
                            {filesInfo.map((item, i) => {
                                return (
                                    <div key={item.id} className={styles.attachment}>
                                        <Info
                                            variant="top"
                                            content={item.name}
                                            className={styles.info}>
                                            {item.preview ? (
                                                <img src={item.preview} alt="" />
                                            ) : (
                                                <Icon name="uploaded_file_format" />
                                            )}
                                        </Info>
                                        <button
                                            onClick={() => {
                                                setFiles((state) =>
                                                    state.filter((item, index) => i !== index)
                                                );
                                                setFilesInfo((state) =>
                                                    state.filter((item, index) => i !== index)
                                                );
                                            }}>
                                            <Icon name="close" width={18} height={18} />
                                        </button>
                                    </div>
                                );
                            })}
                            <button
                                className={styles.add_attachment}
                                onClick={handleAddUploadClick}>
                                <Icon name="plus" />
                            </button>
                        </div>
                    )}
                    <Input
                        className={cx({ withoutInputBorder })}
                        full
                        value={newMessage}
                        onChange={(val) => setNewMessage(val)}
                        placeholder="Write a message..."
                        leftIcon="attach"
                        rightIcon="send"
                        onClickRightIcon={() =>
                            newMessage.length > 0 &&
                            handleSendMessage(id, newMessage, files, () => {
                                setNewMessage('');
                                setFilesInfo([]);
                                setFiles([]);
                                scrollToBottom();
                                refetchTicket(id);
                            })
                        }
                        onClickLeftIcon={handleUploadClick}
                    />
                </div>
            )}
        </div>
    );
};

export default Chat;
