import { useState } from 'react';
import {
    ETicketStatus,
    GetTicketsListResponse,
    TCreateRequestMessage,
    TTicket
} from '@xeppt/xeppt-sdk/types';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAdminService } from '@api';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    isInitialGetList?: boolean;
}

export const useSupport = ({ isInitialGetList }: IProps) => {
    const [tickets, setTickets] = useState<GetTicketsListResponse>();
    const [ticket, setTicket] = useState<TTicket>();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();

    const { isLoading: isTicketsListLoading, handleRequest: refetchTicketsList } = useApiQuery({
        method: () => apiAdminService.getTicketsList(),
        isInitialRequest: isInitialGetList,
        onSuccess: (data) => {
            setTickets(data);
        }
    });

    const { isLoading: isTicketLoading, handleRequest: refetchTicket } = useApiMutation({
        method: (id: string) => apiAdminService.getTicketById(id),
        onSuccess: (data) => {
            setTicket(data);
        }
    });

    const { handleRequest: handleUploadAttachment } = useApiMutation({
        method: (payload: FormData) => apiAdminService.uploadTicketAttachment(payload)
    });

    const { handleRequest: handleSendComment } = useApiMutation({
        method: (payload: { id: string } & TCreateRequestMessage) => {
            return apiAdminService.sendTicketMessage(payload.id, {
                comment: payload.comment,
                uploads: payload.uploads
            });
        },
        onError: requestErrorLocale
    });

    const handleSendMessage = (
        id: string,
        comment: string,
        uploads: FormData[],
        onSuccess?: () => void
    ) => {
        if (uploads.length > 0) {
            Promise.all(uploads.map((item) => handleUploadAttachment(item))).then((data) => {
                //@ts-ignore
                console.log(data.map((item) => item?.token));
                handleSendComment({
                    id,
                    comment,
                    //@ts-ignore
                    uploads: data.map((item) => item?.token)
                }).then(() => onSuccess && onSuccess());
            });
        } else {
            handleSendComment({
                id,
                comment,
                uploads: []
            }).then(() => onSuccess && onSuccess());
        }
    };

    const { handleRequest: handleAssignAdmin } = useApiMutation({
        method: (payload: { id: string; assigneeId: string }) =>
            apiAdminService.assignTicket(payload.id, payload.assigneeId),
        onSuccess: () => {
            refetchTicketsList();
            requestSuccessLocale('Admin was successfully assigned');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleUpdateTicketStatus } = useApiMutation({
        method: (payload: { id: string; status: ETicketStatus }) =>
            apiAdminService.changeTicketStatus(payload.id, payload.status),
        onSuccess: () => {
            refetchTicketsList();
            requestSuccessLocale('Status was successfully updated');
        },
        onError: requestErrorLocale
    });

    return {
        ticket,
        tickets,
        isTicketsListLoading,
        refetchTicketsList,
        isTicketLoading,
        refetchTicket,
        handleSendMessage,
        handleAssignAdmin,
        handleUpdateTicketStatus,
        removeTicketsResponse: () => setTickets(undefined),
        removeTicketResponse: () => setTicket(undefined),
        handleGetTicketData: (id: string) => refetchTicket(id)
    };
};
