import React, { useEffect, useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import moment from 'moment/moment';
import { ETicketStatus, TTicketLite } from '@xeppt/xeppt-sdk/types';
import Status from '@components/common/status';
import { capitalizeFirstLetter, getTicketStatusVariant } from '@utils/index';
import Dropdown from '@components/common/dropdown';
import ChatDrawer from '@sections/chat_drawer';
import { useSupport } from '@hooks/api/useSupport';

const ticketStatuses = [
    ETicketStatus.OPEN,
    ETicketStatus.CLOSED,
    ETicketStatus.PENDING,
    ETicketStatus.HOLD,
    ETicketStatus.SOLVED
];

const TicketsLayout = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [chosenTicket, setChosenTicket] = useState<string>();

    const {
        tickets,
        handleUpdateTicketStatus,
        handleGetTicketData,
        ticket,
        removeTicketResponse,
        refetchTicket
    } = useSupport({
        isInitialGetList: true
    });

    const columns = [
        {
            label: 'ID',
            key: 'id'
        },
        {
            label: 'Subjects',
            key: 'subject'
        },
        {
            label: 'Requester',
            key: 'requester'
        },
        {
            label: 'Requested',
            key: 'requested'
        },
        {
            label: 'Created',
            key: 'createdAt',
            render: (value: Date) => value && moment(value).format('MM.DD.YYYY, HH:mm')
        },
        {
            label: 'Updated',
            key: 'updatedAt',
            render: (value: Date) => value && moment(value).format('MM.DD.YYYY, HH:mm')
        },
        {
            label: 'Assignee',
            key: 'assigneeId'
        },
        {
            label: 'Status',
            key: 'status',
            render: (val: ETicketStatus, data: TTicketLite) => (
                <Status
                    variant={getTicketStatusVariant(val)}
                    isUpdate
                    onUpdate={(val) => handleUpdateTicketStatus({ id: data.id, status: val })}
                    updateOptions={ticketStatuses
                        .filter((item) => item !== val)
                        .map((item) => ({
                            label: capitalizeFirstLetter(item),
                            value: item
                        }))}>
                    {capitalizeFirstLetter(val)}
                </Status>
            )
        },
        {
            label: 'Actions',
            key: 'id',
            isRightAlignment: true,
            render: (val: string, data: TTicketLite) => {
                const options = [
                    {
                        label: 'View Details',
                        onClick: () => {
                            setIsOpen(true);
                            setChosenTicket(val);
                        }
                    }
                ];
                if (data.status === ETicketStatus.SOLVED || data.status === ETicketStatus.CLOSED) {
                    options.push({
                        label: 'Reopen',
                        onClick: () =>
                            handleUpdateTicketStatus({ id: data.id, status: ETicketStatus.OPEN })
                    });
                }
                return <Dropdown items={options} />;
            }
        }
    ];

    useEffect(() => {
        if (chosenTicket) {
            handleGetTicketData(chosenTicket);
        }
    }, [chosenTicket]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Tickets</Typography>
                    <Typography>{tickets?.count} records found</Typography>
                </div>
                {/*<div className={styles.actions}>*/}
                {/*    <RangeDatePicker*/}
                {/*        className={styles.date}*/}
                {/*        placeholder="Select a date range requested"*/}
                {/*    />*/}
                {/*    <RangeDatePicker*/}
                {/*        className={styles.date}*/}
                {/*        placeholder="Select a date range updated"*/}
                {/*    />*/}
                {/*    <Input*/}
                {/*        className={styles.search}*/}
                {/*        placeholder="Search by ID, subject, requester or assigne..."*/}
                {/*        leftIcon="search"*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <Table
                //@ts-ignore
                columns={columns}
                rows={tickets?.requests || []}
                onFilterChange={() => {}}
                totalRows={0}
                emptyDescription="Tickets list is empty"
            />
            <ChatDrawer
                isOpen={isOpen && !!ticket}
                ticket={ticket}
                refetchTicket={refetchTicket}
                onClose={() => {
                    setIsOpen(false);
                    removeTicketResponse();
                    setChosenTicket(undefined);
                }}
            />
        </div>
    );
};

export default TicketsLayout;
