import React, { FC, SVGAttributes } from 'react';

const UploadedFileFormat: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.1984 5.72942C22.8684 6.3449 23.2499 7.21311 23.2499 8.12297V28.8C23.2499 30.1531 22.1529 31.25 20.7999 31.25H3.2C1.8469 31.25 0.75 30.1531 0.75 28.8V3.2C0.75 1.8469 1.8469 0.75 3.2 0.75H15.5111C16.3253 0.75 17.1099 1.05564 17.7096 1.60645L22.1984 5.72942Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <mask id="path-2-inside-1_8799_35084" fill="white">
                <path d="M16 0.799805H22.8V7.5998H16.8C16.3582 7.5998 16 7.24163 16 6.7998V0.799805Z" />
            </mask>
            <path
                d="M16 0.799805H22.8H16ZM22.8 9.0998H16.8C15.5297 9.0998 14.5 8.07006 14.5 6.7998L17.5 6.0998H22.8V9.0998ZM16.8 9.0998C15.5297 9.0998 14.5 8.07006 14.5 6.7998V0.799805H17.5V6.0998L16.8 9.0998ZM22.8 0.799805V7.5998V0.799805Z"
                fill="currentColor"
                mask="url(#path-2-inside-1_8799_35084)"
            />
        </svg>
    );
};

export default UploadedFileFormat;
