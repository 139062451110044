import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { DatePicker as MantineDatePicker } from '@mantine/dates';
import '@mantine/dates/styles.css';
import Input from '@components/common/input';
import moment from 'moment';
import {
    formatDateRange,
    formatToDateString,
    getDisableFeature,
    getDisablePast,
    getLastMonthRangeDates,
    getLastTwoWeeksRangeDates,
    getPreviousCalendarWeekRange,
    getPreviousMonthRangeDates,
    getPreviousYearRangeDates,
    getThisWeekRangeDates,
    getThisYearRangeDates
} from '@utils/date';
import useClickOutside from '@hooks/helpers/useClickOutside';
import { getBirthDisabledDates } from '@utils/index';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';

interface IProps {
    helperText?: string;
    label?: string;
    placeholder?: string;
    full?: boolean;
    isChange?: boolean;
    error?: boolean;
    readOnly?: boolean;
    values?: (string | null)[];
    maxDate?: Date;
    minDate?: Date;
    onChange?: (val: (string | null)[]) => void;
    disabled?: boolean;
    isBirthday?: boolean;
    isDisablePast?: boolean;
    isDisableFeature?: boolean;
    className?: string;
    inputClassName?: string;
}

const buttonLabels = [
    'this_week',
    'last_week',
    'past_two_weeks',
    'this_month',
    'last_month',
    'this_year',
    'last_year'
] as const;

const RangeDatePicker: FC<IProps> = ({
    isDisableFeature,
    isDisablePast,
    isBirthday,
    isChange,
    disabled,
    inputClassName,
    className,
    helperText,
    label,
    placeholder,
    full,
    error,
    readOnly,
    values,
    maxDate,
    minDate,
    onChange
}) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'range_date_picker'
    });
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [textValue, setTextValue] = useState('');
    const [isShown, setIsShown] = useState(true);
    const [checkedRange, setCheckedRange] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const triggerRef = useRef(null);
    const dropdownRef = useRef(null);

    const showDropdown = () => {
        setIsShown(true);
        if (!dropdownRef.current) return;
        console.log('trigger');
        //@ts-ignore
        const triggerRect = triggerRef.current?.getBoundingClientRect();

        // Calculate the position of the dropdown
        const top = triggerRect?.bottom + window.scrollY;
        const left = triggerRect?.left - 200 + window.scrollX;
        // Position the dropdown
        console.log(triggerRect?.left, window.scrollX);
        //@ts-ignore
        dropdownRef.current.style.top = `${top}px`;
        //@ts-ignore
        dropdownRef.current.style.position = `absolute`;
        //@ts-ignore
        dropdownRef.current.style.display = `flex`;
        //@ts-ignore
        dropdownRef.current.style.left = `${left - 100}px`;
        setIsOpen(true);
    };

    const hideDropdown = () => {
        setIsShown(false);
        if (dropdownRef) {
            //@ts-ignore
            dropdownRef.current.style.display = `none`;
            setIsOpen(false);
        }
        setIsShown(true);
    };

    useClickOutside({
        ref: dropdownRef,
        callback: () => isOpen && hideDropdown()
    });

    useEffect(() => {
        if (!values?.[0] && !values?.[1]) {
            setTextValue('');
        } else {
            setTextValue(
                `${values?.[0] ? moment(formatToDateString(new Date(values[0]), '23:59:59')).format('MM/DD/YYYY') : ''} - ${values?.[1] ? moment(formatToDateString(new Date(values[1]), '23:59:59')).format('MM/DD/YYYY') : ''}`
            );
        }
    }, [values]);

    const calculateMinDate = useMemo(() => {
        if (minDate) return minDate;
        if (isDisablePast) return getDisablePast();
        return undefined;
    }, [minDate, isDisablePast]);

    const calculateMaxDate = useMemo(() => {
        if (maxDate) return maxDate; // Fixed a bug here assuming maxDate instead of minDate
        if (isDisableFeature) return getDisableFeature();
        if (isBirthday) return getBirthDisabledDates();
        return undefined;
    }, [maxDate, isDisableFeature, isBirthday]);

    const calculateDefaultValues = useMemo(() => {
        return [
            calculateMinDate ? calculateMinDate : null,
            calculateMaxDate ? calculateMaxDate : null
        ]; // Returns a pair of default values
    }, [calculateMaxDate, calculateMinDate]);

    const handleChange = (dates: (Date | null)[]) => {
        const { dateFrom, dateTo } = formatDateRange(dates?.[0], dates?.[1]);
        onChange && onChange([dateFrom, dateTo]);
    };

    useEffect(() => {
        if (isFirstRender && values?.[0] && values?.[1] && isChange) {
            handleChange(calculateDefaultValues.map((item) => (item ? item : null)));
        }
        setIsFirstRender(false);
    }, [calculateDefaultValues]);

    const onClickPrepaid = (value: (typeof buttonLabels)[number]) => {
        if (onChange) {
            setCheckedRange(value);
            setIsShown(false);
            setTimeout(() => setIsShown(true), 0);
            switch (value) {
                case 'this_week':
                    handleChange(getThisWeekRangeDates(calculateMinDate, calculateMaxDate));
                    break;
                case 'last_week':
                    handleChange(getPreviousCalendarWeekRange(calculateMinDate, calculateMaxDate));
                    break;
                case 'past_two_weeks':
                    handleChange(getLastTwoWeeksRangeDates(calculateMinDate, calculateMaxDate));
                    break;
                case 'this_month':
                    handleChange(getLastMonthRangeDates(calculateMinDate, calculateMaxDate));
                    break;
                case 'last_month':
                    handleChange(getPreviousMonthRangeDates(calculateMinDate, calculateMaxDate));
                    break;
                case 'this_year':
                    handleChange(getThisYearRangeDates(calculateMinDate, calculateMaxDate));
                    break;
                case 'last_year':
                    handleChange(getPreviousYearRangeDates(calculateMinDate, calculateMaxDate));
                    break;
            }
        }
    };

    return (
        <div className={`wrapper ${full ? 'full' : ''}`}>
            <div ref={triggerRef}>
                <Input
                    value={textValue}
                    rightIcon={values?.[0] || values?.[1] ? 'close' : 'calendar'}
                    full={full}
                    readOnly={readOnly}
                    helperText={helperText}
                    inputClassName={inputClassName}
                    className={className}
                    error={error}
                    disabled={disabled}
                    label={label}
                    placeholder={placeholder || 'MM/DD/YYYY - MM/DD/YYYY'}
                    onClickIcon={() => {
                        if (values?.[0] || values?.[1]) {
                            handleChange([null, null]);
                        } else {
                            isOpen ? hideDropdown() : showDropdown();
                        }
                    }}
                    onFocus={showDropdown}
                />
            </div>
            {isShown &&
                createPortal(
                    <div className="datepicker_wrapper_range" ref={dropdownRef}>
                        <div className="prepaid_dates">
                            {buttonLabels.map((item, i) => {
                                return (
                                    <button
                                        key={i}
                                        className={item === checkedRange ? 'checked' : ''}
                                        onClick={() => onClickPrepaid(item)}>
                                        {t(item)}
                                    </button>
                                );
                            })}
                        </div>
                        <MantineDatePicker
                            //@ts-ignore
                            value={
                                values?.map((item) => (item ? new Date(item) : null)) || [
                                    null,
                                    null
                                ]
                            }
                            onChange={(val) => {
                                setCheckedRange('');
                                if (onChange) {
                                    if (!values?.[0] && !values?.[1]) {
                                        handleChange([
                                            val[0] ? val[0] : null,
                                            values?.[1] ? new Date(values?.[1]) : null
                                        ]);
                                    } else if (values?.[0] && values?.[1]) {
                                        handleChange([val[0] ? val[0] : null, null]);
                                    } else if (values?.[0]) {
                                        handleChange([
                                            values?.[0] ? new Date(values?.[0]) : null,
                                            val[1] ? val[1] : null
                                        ]);
                                    }
                                }
                            }}
                            className="dropdown_range"
                            // defaultDate={values}
                            maxDate={calculateMaxDate}
                            type="range"
                            minDate={calculateMinDate}
                            defaultDate={
                                values?.[0] && values?.[1] ? new Date(values?.[0]) : undefined
                            }
                            numberOfColumns={2}
                            hideOutsideDates={false}
                        />
                    </div>,
                    document.body
                )}
        </div>
    );
};

export default RangeDatePicker;
